import styled from 'react-emotion';

const Container = styled.div`
  ${tw`container mx-auto pt-4 px-6 md:px-12 w-full`};
  @media (min-width: 567px) {
    max-width: 576px;
  }
  @media (min-width: 768px) {
    max-width: 800px;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export default Container;
