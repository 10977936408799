import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './header';
import Footer from './footer';
import './layout.css';
import 'typeface-dosis';
import 'typeface-open-sans';

const Content = styled.div`
  ${tw`px-0 pt-0 pb-0 font-sans`};
  flex-grow:1;
  overflow-x:hidden;
`
const WaLink = styled.a`
  ${tw`fixed inline-flex items-center p-2 px-2 rounded-full no-underline px-4`};
  right:5rem;
  bottom:1rem;
  color:white;
  background-color:#55bd22;
  border-radius: 1.5rem;
  z-index: 10004;
  @media screen and (min-width:760px){
    right:4.5rem;
    bottom:.75rem;
  }
  @media screen and (min-width:1030px){
    right:7.5rem;
    bottom:.75rem;
  }
`
const WaLogo = styled.img`
  ${tw`m-0 inline-flex w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6`}
`
const WaText = styled.span`
  ${tw`text-white font-medium font-sans text-sm ml-2 xl:inline-flex`};
  
`
const AllContainer = styled.div`
  display:flex;
  min-height:100vh;
  flex-direction:column;
`
const Layout = (props) => {
  return (
    <>
      <AllContainer>
        <Header />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover={false}
        />
        <Content>{props.children}</Content>
        <Footer />
        <WaLink href="https://mauorder.online/hi-travelwifi" target="_blank">
          <WaLogo src="/images/walogo.png" alt="whatsapp contact" />
          <WaText>Pesan Kami Di Whatsapp</WaText>
        </WaLink>
        {/* <div class="wa-message-us"
            number="622150890670"
            label="Pesan Kami Di WhatsApp"
            color="green"
            size="standard"
            border_radius="4px">
          <script src="https://cdn.smooch.io/whatsapp/message-us-btn.min.js" type="text/javascript"></script>
        </div> */}
      </AllContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapDispatchToProps = dispatch => ({
  changeReferralCode: (code) => ({ type: 'CHANGE_REFERRAL_CODE', code })
});

export default connect(null, mapDispatchToProps)(Layout);
