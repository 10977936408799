import React from 'react';
import styled from 'react-emotion';

import 'typeface-open-sans';

const Logo = styled.img`
  ${tw`h-16 mb-1`};
`

const FooterContainer = styled.div`
    ${tw`p-6 pb-0 flex flex-col md:flex-row-reverse md:px-10`};
    background-color: #c4c4c4;
    font-family: Open Sans;
    font-size: 14px;
    width: 100%;
`
const FooterCol = styled.div`
    ${tw`flex w-full mb-5 text-center text-black items-center md:w-1/2 lg:px-10`};
`
const PaymentContainer = styled.div`
    ${tw`flex flex-col pb-5 md:pb-0`};
    background-color: transparent;
    font-size: 15px;
    width: 100%;
`
const PaymentRow = styled.div`
    ${tw`flex justify-center flex-row w-full flex-wrap mt-4`}
`
const Payment = styled.div`
    ${tw`w-1/3 mb-1 md:w-1/4`}
`
const PaymentLogo = styled.img`
  ${tw`mb-0 px-1`};
`
const NavAnchor = styled.a`
  ${tw`text-black font-bold hover:text-red no-underline`};
`
const FootHead = styled.div`
  ${tw`font-bold text-base`}
`
const FootComp = styled.div`
  ${tw`font-medium md:text-left ml-4`};
  font-size: .9rem
`
const FootSocial = styled.p`
  ${tw`mb-0 mt-2`}
`
const Footer = () => (
    <FooterContainer>
        <FooterCol>
            <PaymentContainer>
                <FootHead>Trusted Payment</FootHead>
                <PaymentRow>
                    <Payment>
                        <PaymentLogo src="/images/payment/gopay.png" alt="logo GoPay" />
                    </Payment>
                    <Payment>
                        <PaymentLogo src="/images/payment/bca.png" alt="logo Bank BCA" />
                    </Payment>
                    <Payment>
                        <PaymentLogo src="/images/payment/bni.png" alt="logo Bank BNI" />
                    </Payment>
                    <Payment>
                        <PaymentLogo src="/images/payment/mandiri.png" alt="logo Bank Mandiri" />
                    </Payment>
                    <Payment>
                        <PaymentLogo src="/images/payment/permata.png" alt="logo Bank Permata" />
                    </Payment>
                    <Payment>
                        <PaymentLogo src="/images/payment/alto.png" alt="logo Alto Network" />
                    </Payment>
                    <Payment>
                        <PaymentLogo src="/images/payment/atmbersama.png" alt="logo ATM Bersama" />
                    </Payment>
                    <Payment>
                        <PaymentLogo src="/images/payment/prima.png" alt="logo Prima Payment Solution" />
                    </Payment>
                </PaymentRow>
                <PaymentRow>
                    <Payment>
                        <PaymentLogo src="/images/payment/sslsecure.png" alt="logo SSL Secure Connection" />
                    </Payment>
                    <Payment>
                        <PaymentLogo src="/images/payment/jcb.png" alt="logo JCB" />
                    </Payment>
                </PaymentRow>
            </PaymentContainer>
        </FooterCol>
        <FooterCol>
            <FootComp>
                <Logo src="/images/logo.png" alt="logo wifirepublic" />
                <FootHead>PT Republik Teknologi Kreasi Negeri</FootHead>
                    Epicentrum Walk, Lantai 6 Unit B636,  <br />
                    Jl. H. R. Rasuna Said, RT.2/RW.5, Karet Kuningan, Kecamatan Setiabudi, Jakarta Selatan 12940 <br />
                    Telp. 021 - 29101008<br />
                <FootSocial>
                <NavAnchor href="https://mauorder.online/hi-travelwifi" target="_blank" activeStyle={{ color: '#fff' }}>
                    Hubungi Kami
                </NavAnchor> |&nbsp;
                <NavAnchor href="https://www.instagram.com/wifirepublic/" target="_blank" activeStyle={{ color: '#fff' }}>
                    Instagram
                </NavAnchor> |&nbsp;
                <NavAnchor href="https://www.facebook.com/wifirepublic/" target="_blank" activeStyle={{ color: '#fff' }}>Facebook
                </NavAnchor>
                        
                </FootSocial>
            </FootComp>
        </FooterCol>
        
    </FooterContainer>
);

export default Footer;