import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'react-emotion'
import ReactPixel from 'react-facebook-pixel'; 
import { slide as Menu } from 'react-burger-menu';
import { connect } from 'react-redux';
import moment from 'moment';
import localization from 'moment/locale/id';

const HeaderContainer = styled.div`
  ${tw`shadow`}
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`

const Logo = styled.img`
  ${tw`h-12 m-0 md:h-12`};
`
const Nav = styled.nav`
  ${tw`flex items-center justify-between bg-white flex-wrap py-1 font-sans md:py-2`};
`
const LogoContainer = styled.div`
  ${tw`flex items-center flex-no-shrink text-white mr-6`};
`
const RightMenu = styled.div`
  ${tw`block flex`};
  order: 2;
  @media (min-width: 992px) {
    order: 3;
  }
`
const ButtonIcon = styled.button`
  ${tw`relative block flex items-center h-full px-3 py-2 border-transparent bg-transparent rounded text-grey-darker hover:text-black cursor-pointer`};
`

const ShoppingCartMark = styled.div`
  ${tw`bg-red w-3 h-3 absolute`};
  border-radius: 50%;
  top: 2px;
  right: 2px;
  color: white;
`
const SocMedContainer = styled.div`
  ${tw`flex items-center justify-center flex-col md:flex-row`}
`

const SocialMediaIcon = styled.button`
${tw`relative block flex items-center pl-0 md:pl-2 border-transparent bg-transparent rounded text-grey-darker hover:text-black cursor-pointer`};
`

const HamburgerIcon = styled.button`
  ${tw`block flex items-center pr-1 pl-3 my-1 ml-1 border-transparent bg-transparent font-bold text-grey-darker hover:text-black cursor-pointer lg:hidden`};
  border: none;
  border-left:solid 2px #606F7B
`
const SVGIcon = styled.svg`
  ${tw`fill-current h-5 w-5`};
`
const NavElementsContainer = styled.div`
  ${tw`w-full block flex-grow lg:flex lg:items-center lg:w-auto`};
  order: 3;
  @media (min-width: 992px) {
    order: 2;
  }
`
const NavElements = styled.div`
  ${tw`text-sm lg:flex-grow hidden lg:block`};
`
const NavLink = styled(Link)`
  ${tw`block lg:inline-block mt-0 text-grey-darker hover:text-black ml-4 no-underline`};
`

const NavAnchor = styled.a`
  ${tw`block lg:inline-block mt-0 text-grey-darker hover:text-black ml-4 no-underline`};
`

const VerticalLine = styled.div`
  ${tw`mx-2 my-1`};
  border-left: 2px solid #606F7B;
`
const RedHR = styled.hr`
  ${tw`bg-red`}
`
const SocialMediaMenu = styled.div`
  ${tw`justify-center`}
  display: flex !important
`
const HContainer = styled.div`
  ${tw`container mx-auto px-6 md:px-12 w-full`};
  padding-left:.75rem;
  padding-right:.25rem;
  @media (min-width: 567px) {
    max-width: 576px;
  }
  @media (min-width: 768px) {
    max-width: 800px;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;
const CartImg = styled.img`
  height:30px;
  margin-bottom:0;
`
const contactClickedHandler = () => {
  ReactPixel.track('Contact')
}

const menuStyle = {
  bmBurgerButton: {
    display: 'none',
    position: 'absolute',
    width: '15px',
    height: '10px',
    right: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    display: 'none',
    background: '#373a47'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenu: {
    background: '#faea18',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#686767',
    padding: '0.8em'
  },
  bmItem: {
    display: 'block',
    margin: '20px 0px'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  },
  zIndex: 2
}

class Header extends Component {
  state = {
    showMenu: false
  };

  componentDidMount() {
    ReactPixel.init(process.env.GATSBY_PIXEL_ID);
    moment.updateLocale('id',localization)
  }

  toggleMenu = () => {
    this.setState((state) => ({ showMenu: !state.showMenu }));
  };

  handleMenuStateChange (state) {
    this.setState({showMenu: state.isOpen})  
  }

  render() {
    const { showMenu } = this.state;

    return(
      <>
        <Menu
          styles={ menuStyle } 
          right
          isOpen={ this.state.showMenu }
          burgerButtonClassName={ 'burgerButton' }
          onStateChange={(state) => this.handleMenuStateChange(state)}
        >
          <NavLink to="/" activeStyle={{ color: '#353d44' }}>
            Home
          </NavLink>
          <RedHR />
          <NavLink to="/products/internetluarnegeri/" activeStyle={{ color: '#353d44' }}>
            Internet Luar Negeri
          </NavLink>
          <RedHR />
          <NavLink to="/products/internetindonesia/" activeStyle={{ color: '#353d44' }}>
            Internet Indonesia
          </NavLink>
          <RedHR />
          {/* <NavLink to="/products/simcard/" activeStyle={{ color: '#353d44' }}>
            International SIM Card
          </NavLink>
          <RedHR /> */}
          <NavLink to="/products/reseller/" activeStyle={{ color: '#353d44' }}>
            Reseller
          </NavLink>
          <RedHR />
          <NavAnchor href="https://news.wifirepublic.id/" target="_blank" activeStyle={{ color: '#353d44' }}>
            News
          </NavAnchor>
          <RedHR />
          <NavLink to="/net_package/subscribe/" activeStyle={{ color: '#353d44' }}>
            Package Extension
          </NavLink>
          <SocialMediaMenu>
            <a href="https://www.instagram.com/wifirepublic/" target="_blank" onClick={contactClickedHandler}>
              <ButtonIcon>
                <SVGIcon viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></SVGIcon>
              </ButtonIcon>
            </a>
            <a href="https://www.facebook.com/wifirepublic/" target="_blank" onClick={contactClickedHandler}>
              <ButtonIcon>
                <SVGIcon viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229" /></SVGIcon>
              </ButtonIcon>
            </a>
          </SocialMediaMenu>
        </Menu>
        <HeaderContainer>
          <HContainer>

            <Nav>
              <LogoContainer>
                <Link to='/' style={{display:'flex',alignItems:'center'}}>
                  <Logo src="/images/logo.png" alt="logo wifirepublic" />
                </Link>
              </LogoContainer>

              <NavElementsContainer>
                <NavElements
                  showMenu={showMenu}
                >
                  <NavLink to="/" activeStyle={{ color: '#353d44', fontWeight: 600 }}>
                    Home
                  </NavLink>
                  <NavLink to="/products/internetluarnegeri/" activeStyle={{ color: '#353d44', fontWeight: 600 }}>
                    Internet Luar Negeri
                  </NavLink>
                  <NavLink to="/products/internetindonesia/" activeStyle={{ color: '#353d44', fontWeight: 600 }}>
                    Internet Indonesia
                  </NavLink>
                  {/* <NavLink to="/products/simcard/" activeStyle={{ color: '#353d44', fontWeight: 600 }}>
                    International SIM Card
                  </NavLink> */}
                  <NavLink to="/products/reseller/" activeStyle={{ color: '#353d44', fontWeight: 600 }}>
                    Reseller
                  </NavLink>
                  <NavAnchor href="https://news.wifirepublic.id/" target="_blank" activeStyle={{ color: '#353d44', fontWeight: 600 }}>
                    News
                  </NavAnchor>
                  <NavLink to="/net_package/subscribe/" activeStyle={{ color: '#353d44', fontWeight: 600 }}>
                    Package Extension
                  </NavLink>
                </NavElements>
              </NavElementsContainer>

              <RightMenu>
                <NavLink to="/cart">
                  <ButtonIcon>
                    {(this.props.shoppingCart && this.props.shoppingCart.length > 0) ? <ShoppingCartMark /> : null }
                    <SVGIcon viewBox="0 0 510 510" xmlns="http://www.w3.org/2000/svg"><title>Shopping Cart</title><path d="M153,408c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S181.05,408,153,408z M0,0v51h51l91.8,193.8L107.1,306 c-2.55,7.65-5.1,17.85-5.1,25.5c0,28.05,22.95,51,51,51h306v-51H163.2c-2.55,0-5.1-2.55-5.1-5.1v-2.551l22.95-43.35h188.7 c20.4,0,35.7-10.2,43.35-25.5L504.9,89.25c5.1-5.1,5.1-7.65,5.1-12.75c0-15.3-10.2-25.5-25.5-25.5H107.1L84.15,0H0z M408,408 c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S436.05,408,408,408z"/></SVGIcon>
                  </ButtonIcon>
                </NavLink>
                <HamburgerIcon onClick={this.toggleMenu}>
                  MENU
                </HamburgerIcon>
                <VerticalLine />
                <SocMedContainer>
                  <a href="https://www.instagram.com/wifirepublic/" target="_blank">
                    <SocialMediaIcon>
                      <SVGIcon viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                        <title>Instagram</title>
                        <path fill="currentColor" d="M 15.871094 2.976563 C 8.761719 2.976563 2.976563 8.761719 2.976563 15.871094 L 2.976563 33.730469 C 2.976563 40.839844 8.761719 46.628906 15.871094 46.628906 L 33.730469 46.628906 C 40.839844 46.628906 46.628906 40.839844 46.628906 33.730469 L 46.628906 15.871094 C 46.628906 8.761719 40.839844 2.976563 33.730469 2.976563 Z M 15.871094 4.960938 L 33.730469 4.960938 C 39.769531 4.960938 44.644531 9.835938 44.644531 15.871094 L 44.644531 33.730469 C 44.644531 39.769531 39.769531 44.644531 33.730469 44.644531 L 15.871094 44.644531 C 9.835938 44.644531 4.960938 39.769531 4.960938 33.730469 L 4.960938 15.871094 C 4.960938 9.835938 9.835938 4.960938 15.871094 4.960938 Z M 36.707031 10.914063 C 35.609375 10.914063 34.722656 11.800781 34.722656 12.898438 C 34.722656 13.992188 35.609375 14.882813 36.707031 14.882813 C 37.804688 14.882813 38.691406 13.992188 38.691406 12.898438 C 38.691406 11.800781 37.804688 10.914063 36.707031 10.914063 Z M 24.800781 13.890625 C 18.789063 13.890625 13.890625 18.789063 13.890625 24.800781 C 13.890625 30.816406 18.789063 35.714844 24.800781 35.714844 C 30.816406 35.714844 35.714844 30.816406 35.714844 24.800781 C 35.714844 18.789063 30.816406 13.890625 24.800781 13.890625 Z M 24.800781 15.871094 C 29.742188 15.871094 33.730469 19.859375 33.730469 24.800781 C 33.730469 29.742188 29.742188 33.730469 24.800781 33.730469 C 19.859375 33.730469 15.871094 29.742188 15.871094 24.800781 C 15.871094 19.859375 19.859375 15.871094 24.800781 15.871094 Z " />
                      </SVGIcon>
                    </SocialMediaIcon>
                  </a>
                  <a href="https://www.facebook.com/wifirepublic/" target="_blank">
                    <SocialMediaIcon>
                      <SVGIcon viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                        <title>Facebook</title>
                        <path fill="currentColor" d="M 24.800781 1.984375 C 12.21875 1.984375 1.984375 12.21875 1.984375 24.800781 C 1.984375 37.382813 12.21875 47.617188 24.800781 47.617188 C 37.382813 47.617188 47.617188 37.382813 47.617188 24.800781 C 47.617188 12.21875 37.382813 1.984375 24.800781 1.984375 Z M 31.746094 15.871094 L 28.484375 15.871094 C 26.5625 15.871094 25.792969 16.324219 25.792969 17.664063 L 25.792969 20.832031 L 31.746094 20.832031 L 30.753906 25.792969 L 25.792969 25.792969 L 25.792969 38.691406 L 19.839844 38.691406 L 19.839844 25.792969 L 16.863281 25.792969 L 16.863281 20.832031 L 19.839844 20.832031 L 19.839844 18.082031 C 19.839844 13.890625 21.515625 10.914063 26.371094 10.914063 C 28.972656 10.914063 31.746094 11.90625 31.746094 11.90625 Z " />
                      </SVGIcon>
                    </SocialMediaIcon>
                  </a>
                </SocMedContainer>
              </RightMenu>
            </Nav>
          </HContainer>
        </HeaderContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart
});

export default connect(mapStateToProps)(Header);
